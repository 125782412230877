import React from "react";
import { Link } from "react-router-dom";

function Portfolio({ portfolio: { id, name, category, image, slug } }) {
  return (
    // <a href={`works/${id}/${slug}`}></a>
    <Link to={`works/${id}/${slug}`}>
      <div className="portfolio-item">
        <div className="details">
          <h4 className="title">{name}</h4>
          <span className="term">{category.join(",  ")}</span>
        </div>
        <span className="plus-icon" style={{fontWeight:'bold'}}>{`>`}</span>
        <div className="thumb">
          <img src={image} alt={name} width={name === 'Store Poin' ? '40%':''} />
          <div className="mask"></div>
        </div>
      </div>
    </Link>
  );
}

export default Portfolio;
