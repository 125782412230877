import React, { useState } from "react";

const contactData = {
  phone: ["+62 813 5553 8585", "+62 882 0197 95788"],
  email: ["reynaldyrahmat@gmail.com"],
  location: "Makassar, Sulawesi Selatan. Indonesia",
};

function Contact() {
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone_no: "",
    message: "",
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formdata.name) {
      setError(true);
      setMessage("🙅‍♂️ Name is required");
    } else if (!formdata.email) {
      setError(true);
      setMessage("🙅‍♂️ Email is required");
    } else if (!formdata.message) {
      setError(true);
      setMessage("🙅‍♂️ Message is required");
    } else {

      if(formdata.phone_no !== '') {
        
        let phone_no = '';
        if(formdata.phone_no.includes('08')) {
          phone_no = formdata.phone_no.replace('08', '628');
        } else if(formdata.phone_no.includes('628')) {
          phone_no = formdata.phone_no;
        } else {
          setError(true);
          setMessage("🙅‍♂️ Wrong phone number format!");
          return;
        }

        setError(false);

        let postData = {
          'api_key': process.env.REACT_APP_WATZAP_API_KEY,
          'number_key': process.env.REACT_APP_WATZAP_API_NUMBER_KEY,
          'phone_no': phone_no
        }
        
        fetch(`${process.env.REACT_APP_WATZAP_API_BASE_URL}${process.env.REACT_APP_WATZAP_API_VALIDATE_WHATSAPP_NUMBER_URL}`, {
          method: 'POST',
          body: JSON.stringify(postData),
          headers: new Headers({'Content-Type':'application/json; charset=UTF-8'})
        })
        .then((res) => res.json())
        .then((data) => {

          if (data.status === '200' && data.message === "Valid WhatsApp Number") {

            let msgData = postData;
            msgData.message = `Hi 👋, thank's for asking. I will reply you soon 🙂👍`;

            fetch(`${process.env.REACT_APP_WATZAP_API_BASE_URL}${process.env.REACT_APP_WATZAP_API_SEND_MESSAGE_URL}`, {
              method: 'POST',
              body: JSON.stringify(msgData),
              headers: new Headers({'Content-Type':'application/json; charset=UTF-8'})
            });

            setTimeout(() => {
              let meData = postData;
              meData.phone_no = '081355538585';
              meData.message = formdata.message;
              fetch(`${process.env.REACT_APP_WATZAP_API_BASE_URL}${process.env.REACT_APP_WATZAP_API_SEND_MESSAGE_URL}`, {
                method: 'POST',
                body: JSON.stringify(meData),
                headers: new Headers({'Content-Type':'application/json; charset=UTF-8'})
              });
            }, 2000);

          }

          setFormdata({
            name: "",
            email: "",
            phone_no: "",
            message: ""
          })          

        });

        setMessage("Your message has been sent 🚀");
      } else {
        setError(false);
        setMessage("Your message has been sent 🚀");
      }
      


    }
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <div className="row">
      <div className="col-md-4 mb-4 mb-md-0">
        <div className="contact-info mb-5">
          <i className="icon-phone"></i>
          <div className="details">
            <h5>Phone & Whatsapp</h5>
            {contactData.phone.map((singlePhone, index) => (
              <span key={index} style={{'display':'block'}}>{singlePhone}</span>
            ))}
          </div>
        </div>
        <div className="contact-info mb-5">
          <i className="icon-envelope"></i>
          <div className="details">
            <h5>Email</h5>
            {contactData.email.map((singleEmail, index) => (
              <span key={index}>{singleEmail}</span>
            ))}
          </div>
        </div>
        <div className="contact-info">
          <i className="icon-location-pin"></i>
          <div className="details">
            <h5>Location</h5>
            <span>{contactData.location}</span>
          </div>
        </div>
      </div>

      <div className="col-md-8">
        <form className="contact-form" onSubmit={submitHandler}>
          <div className="row">
            <div className="column col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Your name"
                  onChange={handleChange}
                  value={formdata.name}
                />
              </div>
            </div>

            <div className="column col-md-6">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email address"
                  onChange={handleChange}
                  value={formdata.email}
                />
              </div>
            </div>

            <div className="column col-md-12">
              <div className="form-group">
                <input
                  type="number"
                  className="form-control"
                  name="phone_no"
                  placeholder="Whatsapp Number for Fast Response - Example: 081355538585"
                  onChange={handleChange}
                  value={formdata.phone_no}
                />
              </div>
            </div>

            <div className="column col-md-12">
              <div className="form-group">
                <textarea
                  name="message"
                  className="form-control"
                  rows="5"
                  placeholder="Message"
                  onChange={handleChange}
                  value={formdata.message}
                ></textarea>
              </div>
            </div>
          </div>

          <button
            type="submit"
            name="submit"
            value="Submit"
            className="btn btn-default"
          >
            <i className="icon-paper-plane"></i>Send Me Message
          </button>
        </form>
        {handleAlerts()}
      </div>
    </div>
  );
}

export default Contact;
