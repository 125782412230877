import React from "react";
import Service from "../Items/Service";

const servicesData = [
  {
    id: 1,
    name: "Development",
    content: "Web and Mobile Development",
    icon: "icon-globe",
  },
  {
    id: 2,
    name: "Design",
    content: "To be honest, i used template for development purposes. But i can customize it",
    icon: "icon-chemistry",
  },
  // {
  //   id: 3,
  //   name: "Advertising",
  //   content: "Lorem ipsum dolor sit amet consectetuer elit.",
  //   icon: "icon-directions",
  // },
  {
    id: 3,
    name: "SEO",
    content: "Make your web and app stands out from the crowd, i can do it",
    icon: "icon-rocket",
  },
  // {
  //   id: 5,
  //   name: "Copy Write",
  //   content: "Lorem ipsum dolor sit amet consectetuer elit.",
  //   icon: "icon-note",
  // },
  // {
  //   id: 4,
  //   name: "Support",
  //   content: "Lorem ipsum dolor sit amet consectetuer elit.",
  //   icon: "icon-bubbles",
  // },
];

function Services() {
  return (
    <div className="row -mt-20">
      {servicesData.map((service) => (
        <div className="col-md-4 col-sm-6 mt-20" key={service.id}>
          <Service service={service} />
        </div>
      ))}
    </div>
  );
}

export default Services;
