import React from "react";
import TrackVisibility from "react-on-screen";
import Skill from "../Items/Skill";

const skillData = {
  skillContent:
    "Master the skills you use today and develop the skills you need tomorrow.",
  progressData: [
    {
      id: 1,
      name: "Javascript",
      percentage: 75,
    },
    {
      id: 2,
      name: "React.JS",
      percentage: 67,
    },
    {
      id: 3,
      name: "Node.JS",
      percentage: 68,
    },
    {
      id: 31,
      name: "NestJS",
      percentage: 67,
    },    
    {
      id: 4,
      name: "ES6",
      percentage: 87,
    },
    {
      id: 5,
      name: "Typescript",
      percentage: 70,
    },
    {
      id: 6,
      name: "Angular.JS",
      percentage: 46,
    },
    {
      id: 7,
      name: "PHP",
      percentage: 75,
    },
    {
      id: 8,
      name: "Wordpress",
      percentage: 71,
    },
    {
      id: 9,
      name: "Laravel Framework",
      percentage: 84,
    },
    {
      id: 10,
      name: "Lumen Microframework",
      percentage: 72,
    },
    {
      id: 11,
      name: "Codeigniter Framework",
      percentage: 78,
    },
    {
      id: 12,
      name: "Yii Framework",
      percentage: 68,
    },
    {
      id: 13,
      name: "Java",
      percentage: 63,
    },
    {
      id: 14,
      name: "Android",
      percentage: 67,
    },
    {
      id: 15,
      name: "Algorithm & Data Structure",
      percentage: 64,
    },
    {
      id: 16,
      name: "Mysql",
      percentage: 72,
    },
    {
      id: 17,
      name: "Linux",
      percentage: 73,
    },
    {
      id: 18,
      name: "GIT",
      percentage: 65,
    },
    {
      id: 19,
      name: "SMS Gateway",
      percentage: 68,
    },
    {
      id: 20,
      name: "Python",
      percentage: 47,
    },        
    {
      id: 21,
      name: "Golang",
      percentage: 32,
    },        
  ],
};

function Skills() {
  return (
    <>
      <p className="mb-0">{skillData.skillContent}</p>
      <div className="mt-5">
        <div className="row -mt-50">
          {skillData.progressData.map((progress) => (
            <div className="col-md-6 mt-50" key={progress.id}>
              <TrackVisibility once>
                <Skill progress={progress} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Skills;
